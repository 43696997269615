import React from 'react'
import logo from '../images/logo-color@2x.png'
import GenericPageImageContact from '../components/GenericPageImageContact/generic-page-image-contact'

import { graphql } from 'gatsby'
import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import FullWidthTitle from '../components/FullWidthTitle/full-width-title'
import BlogPostRichText from '../components/BlogPostRichText/blog-post-rich-text' 
import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import TeamMemberRichText from '../components/TeamMemberRichText/team-member-rich-text'
import ServiceRichText from '../components/ServiceRichText/service-rich-text'
import PageTitle from '../components/PageTitle/PageTitle'
import FeaturesRound from '../components/FeaturesRound/features-round'
import BasicSection from '../components/BasicSection/basic-section'
import PageSubsection from '../components/PageSubsection/page-sub-section'
import PageContent from '../components/PageSubsection/page-content'
import '../templates/service-page.scss';


export const quert = graphql`
    query($slug: String!) {
        service: contentfulPageSubsection(slug: {eq: $slug}) { 
            id
            slug
            sectionTitle
            sectionBannerText
            bannerButtonOneText
            bannerButtonOneLink {
                      linkUrlAddress
                    }
            sectionText {
            raw
            }
            faqContentModule {
                raw
              }
            sectionImage {
            ...on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: CENTER
                    layout: CONSTRAINED
                    width: 1200
                    placeholder: BLURRED
                    )
                }
                title
            }
            seo {
                metaTitle
                metaDescription {
                  metaDescription
                }
              }
            otherElementTitle{
                otherElementTitle
            }
            otherElementContent{
                raw
            }
            otherElements {
                ... on ContentfulTabs {
                    id
                    title
                    slug
                    tabContent {
                    raw
                    }
                    image {
                        ...on ContentfulAsset {
                            contentful_id
                            __typename
                            gatsbyImageData(
                                aspectRatio: 1.5
                                cropFocus: CENTER
                                layout: CONSTRAINED
                                width: 1200
                                placeholder: BLURRED
                                )
                            }
                            title
                    }
                }
                ... on ContentfulCircleImage {
                    id
                    image {
                        ...on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            layout: CONSTRAINED
                            width: 1200
                            placeholder: BLURRED
                            )
                        }
                    }
                    title
                }
            }
              
        }
    }
`


const ServicePage = ({ data: { service } }) => {

    var bannerButtonOneText = service?.bannerButtonOneText;
    var bannerButtonOneLink = service?.bannerButtonOneLink;
    // if(service.slug === "real-estate-valuation"){ 
    //     bannerButtonOneText = "Fill out the form" ;
    //     bannerButtonOneLink = "" ;
    // }else{
    //     bannerButtonOneText = "Contact Us" ;
    //     bannerButtonOneLink = {linkUrlAddress : `/contact-us/`}
    // }
    return (
        <div className='services-details-section'>
            <Layout className={service.slug}>
                <SeoHelmet
                    metaTitle={service?.seo?.metaTitle}
                    metaDescription={service?.seo?.metaDescription?.metaDescription}
                    ogimage = {service?.sectionImage}
                ></SeoHelmet>

                <GeneralPageSubHeader
                    image={service.sectionImage}
                    imageAlt={service.sectionImage.title}
                    breadcrumb="Home - Doha’s Neighborhoods - "
                    pageName={service.sectionTitle}
                    bannerHeading={service.sectionTitle}
                    bannerText={service?.sectionBannerText? service?.sectionBannerText : service.sectionTitle }
                    // bannerButtonOneLink={{linkUrlAddress : `/contact-us/`}}
                    // bannerButtonOneText={`Contact Us`}
                    bannerButtonOneLink={bannerButtonOneLink}
                    bannerButtonOneText={bannerButtonOneText}

                ></GeneralPageSubHeader>

                <section className="full-width-section">
                    <div className="generic-page-content">
                        <PageTitle
                            title={service.sectionTitle}
                        ></PageTitle>


                        <ServiceRichText
                            // shortText={service.serviceShortText}
                            richText={service.sectionText}
                        ></ServiceRichText>
                        {/* <h2 className="small-h2 red-heading">Leave your Living Space To Us!</h2> */}

                    </div>
                    <div className='generic-page-image-section-desktop'>
                        <GenericPageImageContact
                            image={service.sectionImage}
                            imageAlt={service.sectionImage.title}
                            logo={logo}
                        ></GenericPageImageContact>
                    </div>
                </section>
                {service.otherElements && (service.slug === "the-pearl-island" || service.slug === "gewan-island-qatar") &&
                    <section className="guides-content-section">
                        <h3 className='basic-section'>{service.otherElementTitle?.otherElementTitle}</h3>
                        <PageContent
                            sectionId={'content'}
                            className='content-'
                            key={'content'} 
                            sectionText={service.otherElementContent} 
                        ></PageContent>
                        {service.otherElements.map((content, i) => {
                            return (
                                <PageSubsection
                                    sectionId={content.slug}
                                    className='left-image'
                                    key={i}
                                    subtitle={content.title}
                                    sectionText={content.tabContent}
                                    image={content.image}
                                    imageAlt={content.image.title}
                                ></PageSubsection>
                            )
                        })}
                    </section>


                }
                {service.otherElements && service.slug === "lusail-city" &&
                    <BasicSection>
                        <FeaturesRound title={service.otherElementTitle?.otherElementTitle}></FeaturesRound>
                    </BasicSection>
                }
                 {service.faqContentModule &&
                        <section className='faq-content-section'>
                                <ServiceRichText
                                        // shortText={service.serviceShortText}
                                        richText={service.faqContentModule}
                                    >
                                    </ServiceRichText>
                                    
                                    
                        </section>}
                <section className='service-end-cta-section'>
                    <div className='generic-page-image-section-mobile'>
                        <GenericPageImageContact
                            image={service.sectionImage}
                            imageAlt={service.sectionImage.title}
                            logo={logo}
                        ></GenericPageImageContact>
                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default ServicePage